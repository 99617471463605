import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "browse-edit-modal-container" }
const _hoisted_2 = { class: "browse-edit-modal" }
const _hoisted_3 = { class: "browse-edit-modal-header" }
const _hoisted_4 = {
  class: "flex-row-between-grow",
  style: {"margin-bottom":"10px"}
}
const _hoisted_5 = { class: "app-h-s22-h24-w7-c333" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "app-h-s22-h24-w7-c333",
  style: {"margin-bottom":"20px"}
}
const _hoisted_8 = { class: "browse-edit-modal-toolbar" }
const _hoisted_9 = {
  key: 0,
  style: {"background":"#ffffff","box-shadow":"0px 2px 6px rgba(0, 50, 116, 0.25)","border-radius":"4px","padding":"20px 24px 24px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_BrowseEditEntry = _resolveComponent("BrowseEditEntry")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    style: { flexGrow: 1, maxWidth: '600px' },
    visible: true,
    "body-style": "padding: 0px;",
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
  }, {
    body: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.repositoryDto?.caption), 1),
              (_ctx.repositoryDto?.caption_right)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "app-label-s16-h24-w5-c333",
                    style: {"text-align":"end"},
                    innerHTML: _ctx.repositoryDto.caption_right
                  }, null, 8, _hoisted_6))
                : _createCommentVNode("", true)
            ]),
            (_ctx.mode === 'create')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Создание записи в справочнике "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.mode === 'edit' && _ctx.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.extraInfo.edit.create_update)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 0,
                        class: "form-button",
                        "data-type": "danger",
                        onClick: _ctx.onRemove
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Удалить ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnlyComputed && _ctx.value.edit?.to_archive)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 1,
                        class: "form-button",
                        "data-type": "danger",
                        onClick: _ctx.onArchive
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Архивировать ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnlyComputed && _ctx.value.edit?.to_make_active)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 2,
                        class: "form-button",
                        onClick: _ctx.onMakeActive
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сделать активной ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.readOnlyComputed && _ctx.extraInfo.edit.create_update)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 3,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _ctx.onEditCurrentVersion
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Редактировать ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (!_ctx.readOnlyComputed)
                    ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                        key: 4,
                        class: "form-button",
                        "data-type": "accent",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSaveCurrentVersion()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.mode === 'create')
              ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                  key: 1,
                  class: "form-button",
                  "data-type": "accent",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createEntry()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Сохранить ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ButtonComponent, {
              icon: _ctx.TABLE_ICONS.close,
              class: "button-opacity",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
            }, null, 8, ["icon"])
          ]),
          (_ctx.repositoryDto && _ctx.init)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_BrowseEditEntry, {
                  "current-data": _ctx.currentData,
                  "default-open-headers": _ctx.mode === 'create',
                  "extra-info": _ctx.extraInfo,
                  pname: _ctx.pNameAndPType.pname,
                  ptype: _ctx.pNameAndPType.ptype,
                  "read-only": _ctx.readOnlyComputed,
                  "readonly-object": _ctx.value?.edit?.readonly?.attrs,
                  "repository-dto": _ctx.repositoryDto,
                  onReset: _ctx.onResetField,
                  onChangeField: _ctx.onChangeField
                }, null, 8, ["current-data", "default-open-headers", "extra-info", "pname", "ptype", "read-only", "readonly-object", "repository-dto", "onReset", "onChangeField"])
              ]))
            : _createCommentVNode("", true),
          (
              !_ctx.error && ((_ctx.value === null && _ctx.mode === 'edit') || !_ctx.repositoryDto)
            )
            ? (_openBlock(), _createBlock(_component_ScreenSpinner, {
                key: 1,
                absolute: false,
                dimming: false
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}