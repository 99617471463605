
import {
  ApiHelper,
  ButtonComponent,
  Dictionary,
  EditableHelpComponent,
  EnterModalComponent,
  EnterModalProps,
  GlobalContext,
  HelpHelper,
  isNullOrUndefined,
  KeyboardEventHelper,
  Logger,
  PanelFilters,
  PanelFiltersSet,
  ScreenSpinner,
  sleep,
  TABLE_ICONS,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableModel,
  TableMultiHeaders,
  TablePaginationBottom,
  TableRow,
  VOC_NAMES_DICT,
} from "table";
import { defineComponent } from "vue";
import { VocExtraInfo } from "@/modules/editing-voc/common/types";
import { TableEmitUpdateCell } from "table/dist/components/TableComponent/common/types/TableEmitUpdateCell";
import {
  APP_ICONS,
  DEFAULT_LEFT_COLUMN_NAME,
  HEADER_IDS,
  VOC_CLASSES,
} from "@/common/consts";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { PaginationMode } from "table/dist/types/LazyPaginationState";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import HeaderSaveIndicator from "@/components/HeaderSaveIndicator.vue";
import { ActionButtonDto } from "@/services/ApiActions/types";
import { PageGlobalContext } from "table/dist/services/GlobalContext/types";
import ApiActionsService, { RefreshType } from "@/services/ApiActions/ApiActionsService";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import { HelpHelperTableContext } from "table/dist/classes/HelpHelper";
import BrowseEditService from "@/modules/browse-edit/services/BrowseEditService/BrowseEditService";
import {
  BrowseEditRepositoryDto,
  PNameAndPType,
} from "@/modules/browse-edit/services/BrowseEditService/types";
import TableBrowseEditRepository from "@/modules/browse-edit/classes/TableBrowseEditRepository";
import {
  EditingBrowseEditDataSet,
  EditingBrowseEditDataSetStaticParams,
} from "@/modules/browse-edit/classes/EditingBrowseEditDataSet";
import BrowseEditModal, { BrowseEditModalMode } from "@/modules/browse-edit/components/BrowseEditModal.vue";
import { EditingBrowseEditDataSetData } from "@/modules/browse-edit/common/types";
import { TableMultiHeaderLeft } from "table/dist/components/TableComponent/common/components/TableMultiHeaders/common/types/TableMultiHeaderLeft";
import { BROWSE_EDIT_TABLE_HOTKEYS } from "@/modules/browse-edit/common/consts";
import { TableRowObj } from "table/dist/types/TableRowObj";

// Компонент для отображения части атрибутов из записи справочника
export default defineComponent({
  name: "BrowseEdit",
  components: {
    PanelFilters,
    ScreenSpinner,
    TableComponent,
    TableMultiHeaders,
    ButtonComponent,
    TablePaginationBottom,
    EditableHelpComponent,
    EnterModalComponent,
    TableActionsComponent,
    HeaderSaveIndicator,
    ActionsButtons,
    BrowseEditModal,
  },
  setup() {
    return {
      APP_ICONS,
      VOC_CLASSES,
      VOC_NAMES_DICT,
      TABLE_ICONS,
      HEADER_IDS,
    };
  },
  data() {
    return {
      repositoryDto: null as null | BrowseEditRepositoryDto,
      extraInfo: null as null | VocExtraInfo,
      table: {
        tableDataSet: null as null | TableDataSet,
        factory: null as null | TableDataSetFactory,
        edit: true,
        editingDataSet: null as null | EditingBrowseEditDataSet,
      },
      open: {
        entryModal: null as {
          entry?: { value: Dictionary; uuid: string | undefined };
          mode: BrowseEditModalMode;
        } | null,
        enterModal: null as null | EnterModalProps,
      },
      helpHelper: new HelpHelper<HelpHelperTableContext>(),
      actions: [] as ActionButtonDto[],
      errorMessageLabel: "",
    };
  },
  created() {
    this.$watch(
      () => this.params.page_name,
      async () => {
        await this.initPageName();
        this.table.edit = true;
        this.initEdit();
      },
    );
    this.$watch(
      () => this.table.edit,
      () => {
        this.initEdit();
      },
    );
  },
  async mounted() {
    await this.initPageName();
  },
  deactivated() {
    this.table.tableDataSet?.destroy();
    this.table.editingDataSet?.destroy();
  },
  computed: {
    emptyHelp(): string | undefined {
      if (this.extraInfo?.edit.hint) {
        return "[не задано]";
      }

      return undefined;
    },

    currentTableModelHelp(): TableModel | undefined {
      const context = this.helpHelper.getContext();
      if (!context) {
        return undefined;
      }

      return context.tableDataSet.getModelOrUndefined(context.field);
    },

    currentHelpValue(): string | undefined {
      const model = this.currentTableModelHelp;
      if (!model) {
        return undefined;
      }
      if (model.modelDto.help) {
        return model.modelDto.help;
      }

      return this.emptyHelp;
    },

    params(): { page_name: string } {
      return this.$route.params as any;
    },

    pageUrl(): string {
      return "browse_edit";
    },

    pNameAndPType(): PNameAndPType {
      return {
        pname: this.params.page_name,
        ptype: this.pageUrl,
      };
    },

    leftColumnsComputed(): TableMultiHeaderLeft[] {
      if (!this.repositoryDto) {
        return [];
      }

      const {
        numCol,
        numRow,
        multiselect,
      } =
      this.repositoryDto.table_options ?? {};
      const result: TableMultiHeaderLeft[] = [];
      if (this.repositoryDto.table_options?.multiselect) {
        result.push({
          key: "checkbox",
          type: "checkbox",
        });
      }

      if (numRow === true || typeof numRow === "string") {
        result.push({
          key: "numRow",
          caption:
            typeof numRow === "string" ? numRow : DEFAULT_LEFT_COLUMN_NAME,
        });
      }

      return result;
    },
  },
  methods: {
    initEdit() {
      if (!this.repositoryDto) {
        throw new Error("repositoryDto undefined");
      }

      const edit = this.table.edit && this.repositoryDto.edit.edit;
      const hint = this.extraInfo!.edit.hint;
      this.table.tableDataSet!.setEditMode({
        edit,
        hint,
      });
    },

    async initPageName(refresh: RefreshType = "all") {
      if (!this.params.page_name) {
        return;
      }

      this.errorMessageLabel = "";
      if (refresh === "all") {
        this.actions = [];
        this.repositoryDto = null;
        this.extraInfo = null;
        this.table.tableDataSet = null;
        this.table.factory = null;
      }

      try {
        if (!this.repositoryDto || refresh === "all") {
          this.repositoryDto = await BrowseEditService.getRepository({
            ...this.$route.query,
            ...this.pNameAndPType,
          });
          this.actions = await BrowseEditService.getActions(
            this.repositoryDto,
            this.pNameAndPType,
          );
        }

        if (this.repositoryDto.extra_edit) {
          this.extraInfo = {
            edit: this.repositoryDto.extra_edit,
          };
        } else if (!this.extraInfo || refresh === "all") {
          this.extraInfo = {
            edit: {
              create_update: Boolean(this.repositoryDto.edit.edit),
              hint: false,
            },
          };
        }

        await this.initEditingDataSet(this.repositoryDto, refresh);
      } catch (e) {
        Logger.error({ e });
        this.errorMessageLabel = await ApiHelper.getErrorMessage(e);
      }
    },

    setHelp(pointer: Required<TableCellPointer> | null) {
      const { tableDataSet } = this.table;
      if (!pointer || !tableDataSet) {
        this.helpHelper.setHelp(null);
        return;
      }

      const model = tableDataSet.getModelOrUndefined(pointer.col_name);
      if (!model) {
        return;
      }

      this.helpHelper.setHelp(model.modelDto.help || this.emptyHelp || null, {
        tableDataSet: this.table.tableDataSet as TableDataSet,
        field: pointer.col_name,
      });
    },

    onOpenHelpEdit() {
      const context = this.helpHelper.getContext();
      if (!context) {
        return;
      }

      const model = this.currentTableModelHelp!;
      const title = `Редактирование подсказки для столбца "${model.caption}"`;
      this.open.enterModal = {
        title,
        modelValue: model.modelDto.help || "",
        onUpdateModelValue: async (value: string) => {
          if (await this.onUpdateHint(model, value)) {
            this.onCloseEnterModal();
          }
        },
        onClose: this.onCloseEnterModal,
      };
    },

    onCloseEnterModal() {
      this.open.enterModal = null;
    },

    async onUpdateHint(model: TableModel, text: string) {
      throw new Error("onUpdateHint not implemented");

      // try {
      //   await ApiVocService.changeHint(
      //     {
      //       voc_type: this.vocType,
      //       attr: model.field,
      //     },
      //     { text },
      //   );
      //   model.modelDto.help = text;
      //   this.helpHelper.setHelp(null);
      // } catch (ex: any) {
      //   await ApiHelper.wrapNotifyError(ex, { isError: true });
      //   return false;
      // }
      //
      // return true;
    },

    onEditModal(pointer: TableCellPointer) {
      if (this.repositoryDto?.edit.edit === false) {
        return;
      }

      const tableRow = this.table.tableDataSet?.getRowOrUndefined(pointer.row);
      if (!tableRow) {
        return;
      }

      const value = TableBrowseEditRepository.getRowValueByObj(tableRow);
      if (this.repositoryDto?.edit_action) {
        this.onEditAction(pointer);
        return;
      }

      this.open.entryModal = {
        entry: {
          value,
          uuid: tableRow.uuid,
        },
        mode: "edit",
      };
    },

    onCreateModal() {
      if (this.repositoryDto?.edit_action) {
        this.onEditAction();
        return;
      }

      this.open.entryModal = { mode: "create" };
    },

    onEditAction(pointer?: TableCellPointer) {
      if (!this.repositoryDto?.edit_action) {
        return;
      }

      this.onActionExec(
        this.repositoryDto.edit_action,
        this.getActionContext(pointer),
      );
    },

    onCellKeyDown({
      event,
      pointer,
    }: {
      event: KeyboardEvent;
      pointer: Required<TableCellPointer>;
    }) {
      const eventHelper = new KeyboardEventHelper(event);
      if (eventHelper.prevent.keyF3.isAllTrue) {
        this.onEditModal(pointer);
        return;
      }
      if (
        this.repositoryDto?.edit.edit === false &&
        eventHelper.prevent.keyF2.isAllTrue
      ) {
        this.onEditModal(pointer);
        return;
      }
    },

    onCellDblClick({
      event,
      pointer,
    }: {
      event: MouseEvent;
      pointer: Required<TableCellPointer>;
    }) {
      this.onEditModal(pointer);
    },

    onUpdateCells(updates: TableEmitUpdateCell[]) {
      this.table.tableDataSet!.updateCells(updates);
    },

    onRowDelete(pointers: Required<TableCellPointer>[]) {
      if (pointers.length) {
        this.table.tableDataSet!.rowDelete(pointers);
      }
    },

    async onPagination(mode: PaginationMode) {
      const {
        tableDataSet,
        editingDataSet,
        factory,
      } = this.table;
      if (!editingDataSet || !tableDataSet || !factory) {
        throw new Error("data not found");
      }

      await editingDataSet.paginationLoad(
        mode,
        tableDataSet as any,
        factory as TableDataSetFactory,
      );
    },

    async initEditingDataSet(
      repository: BrowseEditRepositoryDto,
      refresh: RefreshType = "all",
    ) {
      if (refresh === "all") {
        this.table.editingDataSet = null;
        this.table.factory = null;
        this.table.tableDataSet = null;
      }

      let editingDataSet = this.table
        .editingDataSet as EditingBrowseEditDataSet | null;
      if (!editingDataSet || refresh === "all" || refresh === "record") {
        editingDataSet = await this.getEditingDataSet(repository);
      }

      const data = (await editingDataSet.get())!;
      const factory = await this.getTableDataSetFactory(data, repository);
      let tableDataSet = this.table.tableDataSet as TableDataSet | null;
      if (!tableDataSet || refresh === "all") {
        tableDataSet = await factory.create();
      }

      await editingDataSet.onLoad(tableDataSet, factory);
      this.table.tableDataSet = tableDataSet;
      this.table.editingDataSet = editingDataSet;
      this.table.factory = factory;
      this.initEdit();
      tableDataSet.fixCursor();
    },

    async onRefreshRow(tableRow: TableRowObj) {
      if (!this.repositoryDto) {
        return;
      }

      await sleep(500);
      const selected_row = TableRow.getValue(tableRow);
      const newValue = await BrowseEditService.post(this.repositoryDto, {
        action: "read",
        ...this.pNameAndPType,
        selected_row,
        __uuid: tableRow.uuid,
      });
      TableRow.setValue(tableRow, newValue);
    },

    async getEditingDataSet(repository: BrowseEditRepositoryDto) {
      const staticParams: EditingBrowseEditDataSetStaticParams = {
        action: "select",
      };
      const serverFilterSort =
        repository.form.table.options?.server_filter_sort !== false;
      const editingDataSet = new EditingBrowseEditDataSet();
      await editingDataSet.setParams({
        staticParams,
        lazyLoadSize: serverFilterSort ? repository.lazy_load_size : undefined,
        filter: serverFilterSort
          ? (this.$route.query.filter as string)
          : undefined,
        getRowAttrs: (x) => x,
        listUrl: repository.data_source_url,
      });
      return editingDataSet;
    },

    async getTableDataSetFactory(
      data: EditingBrowseEditDataSetData,
      repository: BrowseEditRepositoryDto,
    ) {
      if (!this.pNameAndPType.ptype || !this.pNameAndPType.pname) {
        throw new Error("not found ptype or pname");
      }

      const uniqId = this.pNameAndPType.pname;
      const filtersSaveModule = new PanelFiltersSaveModule(
        `${this.pNameAndPType.ptype}_${uniqId}`,
      );
      await filtersSaveModule.init();
      const filtersSet = new PanelFiltersSet(filtersSaveModule);
      filtersSet.subject.subscribe((data) =>
        applyPanelFiltersData(this.table.tableDataSet as TableDataSet, data),
      );
      return new TableDataSetFactory({
        tableName: this.pNameAndPType.ptype,
        uniqId,
        headers: repository.form.table.headers,
        modelDtoArray: repository.form.table.model,
        rows: data.values.map((x) => x),
        rowsOriginal: data.values,
        types: repository.types,
        repository: new TableBrowseEditRepository(
          repository,
          () => this.pNameAndPType,
        ),
        getSystemField: (model) => `${model.field}`,
        tableDataSetOptions: {
          listeners: {
            destroy: {
              next: (tableDataSet) => {
                this.table.editingDataSet?.tableDataSetDestroy(tableDataSet);
              },
            },
            changeFilters: {
              next: async () => {
                if (!this.table.editingDataSet || !this.table.factory) {
                  throw new Error("not found data");
                }

                await this.table.editingDataSet.onChangeFilters(
                  this.table.tableDataSet as any,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
            changeSort: {
              next: async () => {
                if (!this.table.editingDataSet || !this.table.factory) {
                  throw new Error("not found data");
                }

                await this.table.editingDataSet.onChangeSort(
                  this.table.tableDataSet as any,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
          },
          helpHelper: this.helpHelper,
          filtersSet,
          display: {
            rowIndexCol: this.repositoryDto?.table_options?.numCol ?? true,
          },
          uniqueWordWrapSave: true,
          optionsDto: repository.form.table.options,
          hotkeysHelp: BROWSE_EDIT_TABLE_HOTKEYS,
        },
        enableAutoSaveSettings: true,
      });
    },

    getActionContext(pointer: TableCellPointer | null | undefined) {
      if (!this.table?.tableDataSet) {
        throw new Error("not found tableDataSet");
      }

      if (isNullOrUndefined(pointer?.row)) {
        return {};
      }

      const tableDataSet = this.table.tableDataSet;
      tableDataSet.updateGlobalContext();
      const { selected_cell } = GlobalContext.get() as PageGlobalContext;

      const selected_row = tableDataSet.getRowOrUndefined(
        pointer!.row,
      )?.original;
      return {
        selected_row,
        selected_cell,
      };
    },

    async onActionExec(
      action: ActionButtonDto,
      context: {
        selected_row?: any;
        selected_cell?: { field?: string | undefined; value?: any } | undefined;
      },
    ) {
      if (!this.table?.tableDataSet) {
        return;
      }

      try {
        const result = await ApiActionsService.execute(action, context);
        const refresh = result.result?.refresh;
        if (refresh) {
          await this.initPageName(refresh);
        }
      } catch (ex) {
        Logger.error(ex);
        await ApiHelper.wrapNotifyError(ex, { isError: true });
      }
    },
  },
});
