import { Dictionary, PaginationDataSet } from "table";

export interface EditingBrowseEditDataSetStaticParams {
  action: string;
}

export class EditingBrowseEditDataSet extends PaginationDataSet<
  Dictionary,
  EditingBrowseEditDataSetStaticParams
> {}
