import { ApiService, HttpService, Logger } from "table";
import {
  BrowseEditDataSourceParams,
  BrowseEditRepositoryDto,
} from "@/modules/browse-edit/services/BrowseEditService/types";
import { ActionButtonDto } from "@/services/ApiActions/types";

class BrowseEditService {
  getQueryParams = ApiService.getQueryParams;

  async getRepository(params: {
    pname: string;
    ptype: string;
  }): Promise<BrowseEditRepositoryDto> {
    const url = ApiService.getUrl(`/api/frontend-model?`, params, true);
    return (await HttpService.get<BrowseEditRepositoryDto>(url)).json;
  }

  async getActions(
    repository: BrowseEditRepositoryDto,
    params: {
      pname: string;
      ptype: string;
    },
  ) {
    const result = repository.actions_list ?? [];
    if (!repository.actions_list_url) {
      return result;
    }

    const url = ApiService.getUrl(`${repository.actions_list_url}?`, {
      action: "list",
      ...params,
    });
    try {
      result.push(
        ...(await HttpService.get<ActionButtonDto[]>(url).then((x) => x.json)),
      );
    } catch (ex) {
      Logger.error("Ошибка при загрузке списка действий по url: " + url, {
        ex,
      });
    }
    return result;
  }

  async post(
    repositoryDto: BrowseEditRepositoryDto,
    params: BrowseEditDataSourceParams,
  ) {
    return (
      await HttpService.post<never>(repositoryDto.data_source_url, {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
    ).json;
  }
}

export default new BrowseEditService();
