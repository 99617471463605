import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "editing-voc-values default-page-container" }
const _hoisted_2 = { class: "flex-column default-page-padding-inline" }
const _hoisted_3 = {
  class: "flex-row-between-grow",
  style: {"margin-bottom":"10px"}
}
const _hoisted_4 = { class: "app-h-s22-h24-w7-c333" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { style: {"display":"flex","justify-content":"space-between","margin-bottom":"10px"} }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { style: {"display":"flex","justify-content":"flex-end","gap":"20px"} }
const _hoisted_9 = {
  key: 1,
  class: "default-page-container default-page-padding-inline",
  style: {"font-size":"30px","color":"#a80000"}
}
const _hoisted_10 = { class: "default-page-padding-inline" }
const _hoisted_11 = { class: "table-scroll-container" }
const _hoisted_12 = ["onClick", "innerHTML", "onMouseup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderSaveIndicator = _resolveComponent("HeaderSaveIndicator")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_ActionsButtons = _resolveComponent("ActionsButtons")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_TablePaginationBottom = _resolveComponent("TablePaginationBottom")!
  const _component_BrowseEditModal = _resolveComponent("BrowseEditModal")!
  const _component_EditableHelpComponent = _resolveComponent("EditableHelpComponent")!
  const _component_EnterModalComponent = _resolveComponent("EnterModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, {
      to: '#' + _ctx.HEADER_IDS.left
    }, [
      _createVNode(_component_HeaderSaveIndicator, {
        "table-data-set": _ctx.table.tableDataSet
      }, null, 8, ["table-data-set"])
    ], 8, ["to"])),
    (_openBlock(), _createBlock(_Teleport, {
      to: '#' + _ctx.HEADER_IDS.right
    }, null, 8, ["to"])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.repositoryDto?.caption), 1),
          (_ctx.repositoryDto?.caption_right)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "app-label-s16-h24-w5-c333",
                style: {"text-align":"end"},
                innerHTML: _ctx.repositoryDto.caption_right
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        (_ctx.table.tableDataSet?.isTableMounted)
          ? (_openBlock(), _createBlock(_Teleport, {
              key: 0,
              disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
              to: '#' + _ctx.table.tableDataSet.idContainerDivUp
            }, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.repositoryDto)
                  ? (_openBlock(), _createBlock(_component_TableActionsComponent, {
                      key: 0,
                      "changes-state": _ctx.repositoryDto.edit.edit ? 'auto' : 'hide',
                      "table-data-set": _ctx.table.tableDataSet,
                      "filter-state": "enable",
                      "row-append-state": "hide"
                    }, {
                      left: _withCtx(() => [
                        (_ctx.extraInfo?.edit.create_update === true)
                          ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                              key: 0,
                              icon: _ctx.APP_ICONS.documentOutline,
                              class: "button-opacity",
                              title: "Создать новую запись в справочнике",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCreateModal()))
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["changes-state", "table-data-set"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7)),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.actions.length)
                    ? (_openBlock(), _createBlock(_component_ActionsButtons, {
                        key: 0,
                        "data-array": _ctx.actions,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (
                _ctx.onActionExec(
                  $event.data,
                  _ctx.getActionContext(_ctx.table.tableDataSet.selected.cursor),
                )
              ))
                      }, null, 8, ["data-array"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 8, ["disabled", "to"]))
          : _createCommentVNode("", true)
      ]),
      (
        (_ctx.table.tableDataSet === null || _ctx.table.factory === null) &&
        !_ctx.errorMessageLabel.length
      )
        ? (_openBlock(), _createBlock(_component_ScreenSpinner, {
            key: 0,
            dimming: false
          }))
        : (_ctx.errorMessageLabel.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.errorMessageLabel), 1))
          : (_ctx.table.tableDataSet && _ctx.table.editingDataSet)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.table.tableDataSet.isTableMounted)
                    ? (_openBlock(), _createBlock(_Teleport, {
                        key: 0,
                        disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                        to: '#' + _ctx.table.tableDataSet.idContainerDivUp
                      }, [
                        (_ctx.table.tableDataSet)
                          ? (_openBlock(), _createBlock(_component_PanelFilters, {
                              key: 0,
                              "data-set": _ctx.table.tableDataSet.options.filtersSet,
                              "model-array": _ctx.table.tableDataSet.modelUnref,
                              "table-data-set": _ctx.table.tableDataSet,
                              types: _ctx.table.tableDataSet.types,
                              style: {"margin-bottom":"10px"},
                              "url-sync": ""
                            }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                          : _createCommentVNode("", true)
                      ], 8, ["disabled", "to"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_TableComponent, {
                    classes: _ctx.VOC_CLASSES,
                    isShowInfo: false,
                    isViewColumnNumber: false,
                    "screen-spinner": _ctx.table.editingDataSet.pagination.loading,
                    "table-data-set": _ctx.table.tableDataSet,
                    "onUpdate:cells": _ctx.onUpdateCells,
                    onRowAppend: _cache[2] || (_cache[2] = ($event: any) => (_ctx.table.tableDataSet.rowAppend())),
                    onRowInsert: _cache[3] || (_cache[3] = ($event: any) => (_ctx.table.tableDataSet.rowInsert($event))),
                    onRowDelete: _ctx.onRowDelete,
                    onCellKeydown: _ctx.onCellKeyDown,
                    onCellDblClick: _ctx.onCellDblClick,
                    "onUpdate:cursor": _cache[4] || (_cache[4] = ($event: any) => (_ctx.setHelp($event)))
                  }, {
                    headers: _withCtx(({ classes, tableDataSet }) => [
                      _createVNode(_component_TableMultiHeaders, {
                        classes: classes,
                        "left-columns": _ctx.leftColumnsComputed,
                        "table-data-set": tableDataSet,
                        onClickLeftCol: ($event: any) => (tableDataSet.selectedAll(this.$nextTick))
                      }, null, 8, ["classes", "left-columns", "table-data-set", "onClickLeftCol"])
                    ]),
                    areport_name: _withCtx(({
              tableDataSet,
              model,
              colIndex,
              rowIndex,
              data,
              original,
              classes,
              type,
            }) => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_router_link, {
                          title: original.areport_name,
                          to: `/editor-forms/${original.areport_id}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.value), 1)
                          ]),
                          _: 2
                        }, 1032, ["title", "to"])
                      ])
                    ]),
                    html: _withCtx(({ data, tableRow }) => [
                      _createElementVNode("span", {
                        onClick: ($event: any) => (_ctx.onRefreshRow(tableRow)),
                        innerHTML: data.value,
                        onMouseup: _withModifiers(($event: any) => (_ctx.onRefreshRow(tableRow)), ["middle"])
                      }, null, 40, _hoisted_12)
                    ]),
                    _: 1
                  }, 8, ["classes", "screen-spinner", "table-data-set", "onUpdate:cells", "onRowDelete", "onCellKeydown", "onCellDblClick"])
                ]),
                (_ctx.table.tableDataSet.isTableMounted)
                  ? (_openBlock(), _createBlock(_Teleport, {
                      key: 0,
                      disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                      to: '#' + _ctx.table.tableDataSet.idContainerDivBottom
                    }, [
                      _createVNode(_component_TablePaginationBottom, {
                        "table-data-set": _ctx.table.tableDataSet,
                        onPagination: _ctx.onPagination
                      }, null, 8, ["table-data-set", "onPagination"])
                    ], 8, ["disabled", "to"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
    ]),
    (_ctx.open.entryModal && _ctx.repositoryDto && _ctx.extraInfo)
      ? (_openBlock(), _createBlock(_component_BrowseEditModal, {
          key: 0,
          "edit-value": _ctx.open.entryModal.entry?.value,
          "extra-info": _ctx.extraInfo,
          mode: _ctx.open.entryModal.mode,
          pname: _ctx.pNameAndPType.pname,
          ptype: _ctx.pNameAndPType.ptype,
          uuid: _ctx.open.entryModal.entry?.uuid,
          onClose: _cache[5] || (_cache[5] = ($event: any) => {
      _ctx.open.entryModal = null;
      _ctx.table.tableDataSet?.tableComponent?.focusTable();
    }),
          onSuccess: _cache[6] || (_cache[6] = ($event: any) => (_ctx.initPageName('record')))
        }, null, 8, ["edit-value", "extra-info", "mode", "pname", "ptype", "uuid"]))
      : _createCommentVNode("", true),
    (
      _ctx.extraInfo &&
      _ctx.currentTableModelHelp &&
      _ctx.helpHelper.visible &&
      (_ctx.currentHelpValue || _ctx.extraInfo.edit.hint)
    )
      ? (_openBlock(), _createBlock(_component_EditableHelpComponent, {
          key: 1,
          editable: _ctx.extraInfo.edit.hint,
          modelValue: _ctx.currentHelpValue,
          title: _ctx.currentTableModelHelp.caption,
          style: {"z-index":"4"},
          onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.helpHelper.setHelp(null))),
          onClickEdit: _ctx.onOpenHelpEdit
        }, null, 8, ["editable", "modelValue", "title", "onClickEdit"]))
      : _createCommentVNode("", true),
    (_ctx.open.enterModal)
      ? (_openBlock(), _createBlock(_component_EnterModalComponent, {
          key: 2,
          "model-value": _ctx.open.enterModal.modelValue,
          readonly: _ctx.open.enterModal.readonly,
          title: _ctx.open.enterModal.title,
          "title-save-button": _ctx.open.enterModal.titleSaveButton,
          onClose: _ctx.open.enterModal.onClose,
          "onUpdate:modelValue": _ctx.open.enterModal.onUpdateModelValue
        }, null, 8, ["model-value", "readonly", "title", "title-save-button", "onClose", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}