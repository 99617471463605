
import {
  defineComponent,
  PropType,
} from "vue";
import {
  APP_ICONS,
  HEADER_IDS,
} from "@/common/consts";
import {
  ApiHelper,
  ButtonComponent,
  copy,
  dateFormat,
  Dictionary,
  isNullOrUndefined,
  isObject,
  Logger,
  ModalComponent,
  NotificationsService,
  RepositoryReadModelDto,
  RepositoryReadTypeDto,
  ScreenSpinner,
  TABLE_CONSTS,
  TABLE_ICONS,
  TableCell,
  toJS,
  VOC_NAMES_DICT,
} from "table";
import { VocExtraInfo } from "@/modules/editing-voc/common/types";
import BrowseEditService from "@/modules/browse-edit/services/BrowseEditService/BrowseEditService";
import {
  BrowseEditRepositoryDto,
  PNameAndPType,
} from "@/modules/browse-edit/services/BrowseEditService/types";
import BrowseEditEntry from "@/modules/browse-edit/components/BrowseEditEntry.vue";

export type BrowseEditModalMode = "create" | "edit";

export default defineComponent({
  name: "BrowseEditModal",
  props: {
    mode: {
      type: String as PropType<BrowseEditModalMode>,
      required: true,
    },
    pname: {
      type: String,
      required: true,
    },
    ptype: {
      type: String,
      required: true,
    },
    editValue: {
      type: Object as PropType<Dictionary>,
      required: false,
    },
    uuid: String,
    extraInfo: {
      type: Object as PropType<VocExtraInfo>,
      required: true,
    },
  },
  components: {
    ScreenSpinner,
    ButtonComponent,
    ModalComponent,
    BrowseEditEntry,
  },
  emits: ["close", "success"],
  setup() {
    return {
      APP_ICONS,
      HEADER_IDS,
      dateFormat,
      TABLE_ICONS,
      VOC_NAMES_DICT,
    };
  },
  data() {
    return {
      repositoryDto: null as null | BrowseEditRepositoryDto,
      readOnly: true,
      initialData: {} as Dictionary,
      currentData: {} as Dictionary,
      value: null as Dictionary | null,
      open: {},
      error: false,
      init: true,
    };
  },
  async created() {
    try {
      this.repositoryDto = await BrowseEditService.getRepository({
        ...this.$route.query,
        ...this.pNameAndPType,
      });
    } catch (e: any) {
      NotificationsService.send({
        type: "error",
        title:
          "Произошла ошибка при загрузке данных из репозитория для справочника",
        text: await ApiHelper.getErrorMessage(e),
      });
      Logger.error({ e });
      this.error = true;
      return;
    }

    await this.initEditValue();
  },
  computed: {
    pNameAndPType(): PNameAndPType {
      return {
        pname: this.pname,
        ptype: this.ptype,
      };
    },
    uuidComputed(): string {
      return this.uuid ?? TABLE_CONSTS.DEFAULT_MAKE_UUID();
    },
    readOnlyComputed() {
      return this.extraInfo.edit.create_update ? this.readOnly : true;
    },
  },
  watch: {
    editValue() {
      this.initEditValue();
    },
  },
  methods: {
    async initEditValue() {
      if (!this.repositoryDto) {
        return;
      }

      this.init = false;
      if (this.mode === "create") {
        this.currentData = this.getInitAttrs();
      }

      this.error = false;
      if (isNullOrUndefined(this.editValue)) {
        this.readOnly = false;
        this.init = true;
        return;
      }

      this.readOnly = false;

      try {
        const value = await BrowseEditService.post(this.repositoryDto, {
          action: "read",
          ...this.pNameAndPType,
          selected_row: this.editValue,
          __uuid: this.uuidComputed,
        });
        this.setValue(value);
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при инициализации записи справочника",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        this.error = true;
        return;
      }

      this.init = true;
    },

    getMergeEditValueAndInitialData() {
      const newVocValueDto: Dictionary = {
        ...copy(this.editValue || {}),
        ...copy(this.initialData),
      };
      return newVocValueDto;
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.currentData[event.model.field] = event.value;
    },

    onCancelEdit() {
      this.currentData = toJS(this.initialData);
      this.readOnly = true;
    },

    onResetField(model: RepositoryReadModelDto) {
      this.currentData[model.field] = this.initialData[model.field];
    },

    async onSaveCurrentVersion() {
      if (!this.repositoryDto) {
        return;
      }

      this.initialData = toJS(this.currentData);
      const newVocValueDto = this.getMergeEditValueAndInitialData();
      try {
        const result = await BrowseEditService.post(this.repositoryDto, {
          action: "change_cells",
          ...this.pNameAndPType,
          selected_row: newVocValueDto,
          __uuid: this.uuidComputed,
        });
        if (isObject(result)) {
          this.setValue(result);
          this.readOnly = true;
          NotificationsService.send({
            type: "success",
            title: "Статус сохранения",
            text: "Изменения сохранены",
            duration: 5000,
          });
          this.$emit("success");
          this.$emit("close");
        }
      } catch (e) {
        Logger.error({ e });
        const errorMessage = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title: "Статус сохранения",
          text: errorMessage,
        });
      }
    },

    setValue(value: Dictionary = {}) {
      this.initialData = copy(value);
      this.currentData = copy(value);
      this.value = value;
    },

    onEditCurrentVersion() {
      this.readOnly = false;
    },

    async onRemove() {
      if (!this.readOnly) {
        this.onCancelEdit();
      }

      if (isNullOrUndefined(this.value) || !this.repositoryDto) {
        Logger.warn("onRemove, что-то не указано:", {
          repositoryDto: this.repositoryDto,
          value: this.value,
        });
        return;
      }

      try {
        const result = await BrowseEditService.post(this.repositoryDto, {
          action: "delete",
          ...this.pNameAndPType,
          selected_row: this.value,
          __uuid: this.uuidComputed,
        });
        this.$emit("success");
        this.$emit("close");
        return;
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "При удалении записи из справочника произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }
    },

    async onArchive() {
    },

    async onMakeActive() {
    },

    // РЕЖИМ СОЗДАНИЯ -----------------------------------------------

    getInitAttrs(): Dictionary {
      return this.repositoryDto!.form.table.model.reduce((attrs, model) => {
        attrs[model.field] = TableCell.getDefaultValue(this.getTypeAttr(model));
        return attrs;
      }, {} as Dictionary);
    },

    getTypeAttr(
      model: RepositoryReadModelDto,
    ): RepositoryReadTypeDto | undefined {
      return this.repositoryDto!.types[model.type || ""];
    },

    async createEntry() {
      if (!this.repositoryDto) {
        return;
      }

      const value: Dictionary = {
        ...copy(this.currentData),
      };

      try {
        const result = await BrowseEditService.post(this.repositoryDto, {
          action: "create",
          ...this.pNameAndPType,
          selected_row: value,
          __uuid: this.uuidComputed,
        });
        if (isObject(result)) {
          NotificationsService.send({
            type: "success",
            title: "Создание записи в справочнике",
            text: `Запись успешно создана`,
            duration: 5000,
          });

          this.setValue(value);
          this.$emit("success", result);
          this.$emit("close");
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Создание записи в справочнике",
          text: (await ApiHelper.getErrorMessage(e)) || "Неизвестная ошибка",
        });
        Logger.error({ e });
        return;
      }
    },
  },
});
