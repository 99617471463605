// Модуль Управление данными

import {
  ApiHelper,
  copy,
  Dictionary,
  EventMoveMode,
  HttpService,
  Logger,
  NotificationsService,
  TableDataSet,
  TableRow,
  unique,
  UpdateRowIndexDto,
  VocValueStatusEnum,
  waitTablesQueue,
} from "table";
import {
  IRepositoryVocQueue,
  IRepositoryVocQueueOnly,
} from "@/modules/editing-voc/services/ApiVocService/types";
import {
  IChangeCellAndPointer,
  IRepository,
  IRepositoryEventContext,
  IRepositoryLoading,
  IRepositoryQueueOnlyMoveRow,
  IRepositoryQueueOnlyUpdateRowIndexes,
  IRepositoryTableRowAndPointer,
} from "table/dist/types/IRepository";
import { TablePointerRow } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { VocValueDto } from "table/dist/services/Api/types/VocRepositoryDto";
import { TableRowChange } from "table/dist/types/TableRowRecord";
import { TableRowObj } from "table/dist/types/TableRowObj";
import {
  BrowseEditRepositoryDto,
  PNameAndPType,
} from "@/modules/browse-edit/services/BrowseEditService/types";
import {
  IRepositoryBrowseEditQueueOnlyChangeCells,
  IRepositoryBrowseEditQueueOnlyDelete,
  IRepositoryBrowseEditQueueOnlySave,
} from "@/modules/browse-edit/common/types";
import { TableDataSetContext } from "table/dist/types/TableDataSetContext";

export function queueIsSave(
  queue: IRepositoryVocQueueOnly,
): queue is IRepositoryBrowseEditQueueOnlySave {
  return queue.type === "save";
}

export default class TableBrowseEditRepository
  implements
    IRepository<
      IRepositoryVocQueueOnly,
      IRepositoryVocQueue,
      IRepositoryLoading<IRepositoryVocQueue>
    >
{
  intervals: any[] = [];
  _executeFree = true;
  _lastError = false;
  _bufferingData: IRepositoryVocQueueOnly[] | undefined = undefined;
  queue: IRepositoryVocQueue = [];

  constructor(
    public repository: BrowseEditRepositoryDto,
    public getParams: () => PNameAndPType,
  ) {}

  static getRowValue(
    tableDataSet: TableDataSet,
    row: number,
  ): VocValueDto & Dictionary {
    const original = tableDataSet.getRowOrUndefined(row)?.original || {};
    const values = tableDataSet.getRowValue(row) || {};
    return { ...original, ...values };
  }

  static getRowValueByObj(tableRow: TableRowObj): VocValueDto & Dictionary {
    const original = tableRow.original || {};
    const values = TableRow.getValue(tableRow);
    return { ...original, ...values };
  }

  // запуск интервала для сохранения изменений в таблице
  runSaveInterval(tableDataSet: TableDataSet) {
    this.intervals.push(
      setInterval(async () => {
        if (this.queue.length === 0 || !this._executeFree) {
          return;
        }

        // сообщаем очереди, что задача выполняется
        this._executeFree = false;

        const task = this.queue[0];
        // запускаем выполнение задачи
        try {
          const result = await this.execute(tableDataSet, task);
          // после успешного сохранения вытаскиваем задачу из очереди
          this.queue.shift();
          this._lastError = false;
        } catch (e: any) {
          const error = await ApiHelper.getError(e);
          if (error.value.not_repeat) {
            NotificationsService.send({
              type: "error",
              title: `Ошибка при сохранении изменений в справочнике. Несохранённые изменения отменены.`,
              text: error.getMessage(),
            });
            await tableDataSet.changes.cancelQueueChanges(tableDataSet);
            return;
          }

          this._lastError = true;
        }

        // сообщаем, что выполнение задачи окончено
        this._executeFree = true;
      }, 100),
    );
  }

  destroy() {
    this.intervals.forEach((x) => clearInterval(x));
  }

  async execute(
    tableDataSet: TableDataSet,
    queueOnly: IRepositoryVocQueueOnly,
  ): Promise<any> {
    const data = {
      action: queueOnly.type,
      ...this.getParams(),
      ...queueOnly.data,
    };
    const result = await HttpService.post(this.repository.data_source_url, {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (queueIsSave(queueOnly)) {
      if (!queueOnly.tableRow) {
        Logger.error("queueOnly.tableRow is undefined", { queueOnly });
        return result;
      }

      if (!queueOnly.tableRow.uuid) {
        Logger.error("queueOnly.tableRow.uuid is undefined", { queueOnly });
        return result;
      }

      const tableRow = tableDataSet.getRowByUUID(
        queueOnly.tableRow.uuid,
        queueOnly.tableRow.row,
      );
      const newVocValueOriginal = result.json as Dictionary;
      if (!tableRow) {
        Logger.error("TableRow is undefined", { queueOnly });
        return result;
      }

      const prevValue = TableRow.getValue(tableRow);
      Object.keys(prevValue).forEach((key) => {
        if (prevValue[key] === "" || prevValue[key] === null) {
          if (
            newVocValueOriginal[key] === undefined ||
            newVocValueOriginal[key] === ""
          ) {
            newVocValueOriginal[key] = prevValue[key];
          }

          delete prevValue[key];
        }
      });
      const newVocValue = Object.assign(copy(newVocValueOriginal), prevValue);
      TableRow.setValue(tableRow, newVocValue);
      TableRow.setOriginal(tableRow, newVocValue);
    }

    return result;
  }

  queuePush(queueOnly: IRepositoryVocQueueOnly, isBuffer = false) {
    // если включен режим буферизации и это не просьба выполнения от буфера - записать в буфер
    if (this._bufferingData !== undefined && !isBuffer) {
      this._bufferingData.push(queueOnly);
      return;
    }

    this.queue.push(queueOnly);
  }

  async addRow(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    rowChange: TableRowChange,
  ) {
    const attrs = rowChange.changeCells.reduce((attrs, cell) => {
      attrs[cell.pointer.col_name] = cell.changeCell.value;
      return attrs;
    }, {} as Dictionary);
    const tableRow = rowChange.tableRow;
    const queueOnly: IRepositoryBrowseEditQueueOnlySave = {
      type: "save",
      data: {
        selected_row: {
          value_status: VocValueStatusEnum.Active,
          ...(rowChange.original || {}),
          ...attrs,
        },
      },
      changeEvents,
      pointer: { row: rowChange.row },
      tableRow: rowChange.tableRow,
    };
    this.queuePush(queueOnly);
  }

  async changeTableCells(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    changeCells: IChangeCellAndPointer[],
  ) {
    await waitTablesQueue();
    const selectedRows: Dictionary<TableDataSetContext["selected_row"]> = {};
    const changes: string[] = [];
    changeCells.forEach((changeCellAndPointer) => {
      const { row } = changeCellAndPointer.pointer;
      selectedRows[row] ??= TableBrowseEditRepository.getRowValue(
        tableDataSet,
        row,
      );
      selectedRows[row]![changeCellAndPointer.pointer.col_name] =
        changeCellAndPointer.changeCell.value;
      changes.push(changeCellAndPointer.pointer.col_name);
    });
    if (Object.keys(selectedRows).length === 0) {
      return;
    }

    Object.values(selectedRows).forEach((selected_row) => {
      const queueOnly: IRepositoryBrowseEditQueueOnlyChangeCells = {
        type: "change_cells",
        data: {
          selected_row,
          changes: unique(changes),
        },
        changeEvents,
      };
      this.queuePush(queueOnly);
    });
  }

  deleteRow(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    pointersAndRows: {
      pointer: TablePointerRow;
      tableRow: TableRowObj;
    }[],
  ) {
    pointersAndRows.forEach((pointerAndRow) => {
      const { tableRow } = pointerAndRow;
      const selected_row = TableBrowseEditRepository.getRowValueByObj(tableRow);
      const queueOnly: IRepositoryBrowseEditQueueOnlyDelete = {
        type: "delete",
        data: {
          selected_row,
        },
        changeEvents,
        pointer: pointerAndRow.pointer,
      };
      this.queuePush(queueOnly);
    });
  }

  moveRow(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    pointer: TablePointerRow,
    move_mode: EventMoveMode,
  ) {
    const queueOnly: IRepositoryQueueOnlyMoveRow = {
      type: "move_row",
      data: {
        pointer,
        move_mode,
      },
      changeEvents,
    };
    this.queuePush(queueOnly);
  }

  updateRowIndexes(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    indexes: UpdateRowIndexDto[],
  ) {
    const queueOnly: IRepositoryQueueOnlyUpdateRowIndexes = {
      type: "update_row_indexes",
      data: {
        indexes,
      },
      changeEvents,
    };
    this.queuePush(queueOnly);
  }

  changeTableRows(
    { changeEvents, tableDataSet }: IRepositoryEventContext,
    table_cells: IRepositoryTableRowAndPointer[],
  ): any {}

  // Объединение нескольких задач в одну (поддерживается только массив с одинаковыми задачами)
  combineQueue(queue: IRepositoryVocQueueOnly[]): IRepositoryVocQueueOnly[] {
    return queue;
  }

  startBuffering() {
    this._bufferingData = [];
  }

  finishBuffering() {
    if (this._bufferingData === undefined) {
      return;
    }

    this._bufferingData.forEach((x) => this.queuePush(x, true));
    this._bufferingData = undefined;
  }
}
