
import {
  AttrsComponent,
  ButtonComponent,
  Dictionary,
  EditableHelpComponent,
  EnterModalComponent,
  EnterModalProps,
  RepositoryReadModelDto,
  TABLE_ICONS,
} from "table";
import {
  RepositoryVocGroupDetailsDto,
  RepositoryVocGroupHeaderDto,
} from "table/dist/services/Api/types/RepositoryVocGroupDto";
import {
  defineComponent,
  PropType,
} from "vue";
import {
  VocExtraInfo,
  VocGroupDetailsFilled,
  VocGroupHeaderFilled,
} from "@/modules/editing-voc/common/types";
import { getVocGroupEnableAttrs } from "@/modules/editing-voc/common/helpers";
import CreateVocEntryButton from "@/components/smart/CreateVocEntryButton.vue";
import { GLOBAL_ATTRS_HANDLERS } from "@/common/consts";
import { BrowseEditRepositoryDto } from "@/modules/browse-edit/services/BrowseEditService/types";

// Компонент для отображения цельной записи из справочника
export default defineComponent({
  name: "BrowseEditEntry",
  props: {
    repositoryDto: {
      type: Object as PropType<BrowseEditRepositoryDto>,
      required: true,
    },
    readOnly: Boolean,
    currentData: {
      type: Object as PropType<Dictionary>,
      required: true,
    },
    defaultOpenHeaders: Boolean,
    pname: {
      type: String,
      required: true,
    },
    ptype: {
      type: String,
      required: true,
    },
    extraInfo: {
      type: Object as PropType<VocExtraInfo>,
      required: false,
    },
    // Объект содержащий свойства только для чтения со значением true. Именно для атрибутов.
    readonlyObject: {
      type: Object as PropType<Dictionary<boolean>>,
    },
  },
  components: {
    CreateVocEntryButton,
    EnterModalComponent,
    EditableHelpComponent,
    AttrsComponent,
    ButtonComponent,
  },
  emits: ["change-field", "reset"],
  setup() {
    return {
      TABLE_ICONS,
      GLOBAL_ATTRS_HANDLERS,
      classAttrsContainer: "voc-group-attrs-container",
    };
  },
  data() {
    return {
      open: {
        entryHeaders: {} as Dictionary<boolean>,
        currentHelp: null as null | {
          model: RepositoryReadModelDto;
          help: string | undefined;
        },
        enterModal: null as null | EnterModalProps,
      },
    };
  },
  created() {
    this.groupFilled?.forEach((header) => {
      this.open.entryHeaders[header.key] = this.defaultOpenHeaders;
    });
  },
  computed: {
    uniqueId(): string {
      return `${this.ptype}__${this.pname}`;
    },

    currentHelpValue(): string | undefined {
      return this.open.currentHelp?.help || this.emptyHelp;
    },

    emptyHelp(): string | undefined {
      if (this.extraInfo?.edit.hint) {
        return "[не задано]";
      }

      return undefined;
    },

    modelDict(): Dictionary<RepositoryReadModelDto> {
      return this.repositoryDto.form.table.model.reduce(
        (modelDict, model) => {
          modelDict[model.field] = model;
          return modelDict;
        },
        {} as Dictionary<RepositoryReadModelDto>,
      );
    },

    groupFilled(): VocGroupDetailsFilled[] | undefined {
      const { group } = this.repositoryDto;
      if (!group) {
        return undefined;
      }

      const result: VocGroupDetailsFilled[] = [];
      group.forEach((group, index) => {
        if (group.type !== "details") {
          throw new Error(`group first level not details`);
        }

        const key = this.getGroupDetailsKey(group, index);
        let headers: VocGroupHeaderFilled[];
        if (typeof group.fields[0] === "string") {
          const groupHeader: RepositoryVocGroupHeaderDto = group as any;
          headers = [
            {
              ...groupHeader,
              modelArray: groupHeader.fields
                .map((field) => this.modelDict[field])
                .filter((model) => this.isModelEnable(model)),
              key: `${index}`,
            },
          ];
        } else {
          const headersDto =
            group.fields as any as RepositoryVocGroupHeaderDto[];
          headers = headersDto.map((headerDto, index) => {
            return {
              ...headerDto,
              key: `${headerDto.caption}_${index}`,
              modelArray: headerDto.fields
                .map((field) => this.modelDict[field])
                .filter((model) => this.isModelEnable(model)),
            };
          });
        }

        if (headers.length === 0) {
          return;
        }

        result.push({
          ...group,
          key,
          headers: headers.filter((x) => x.modelArray.length !== 0),
        });
      });
      return result;
    },

    enableAttrs() {
      return getVocGroupEnableAttrs(
        this.repositoryDto.form.table.model,
        this.currentData,
      );
    },
  },
  methods: {
    async onUpdateHint(model: RepositoryReadModelDto, text: string) {
      throw new Error("onUpdateHint not implemented");
    },

    onCloseEnterModal() {
      this.open.enterModal = null;
    },

    onOpenHelpEdit() {
      const model = this.open.currentHelp?.model;
      if (!model) {
        return;
      }

      const title = `Редактирование подсказки для атрибута "${model.caption}"`;
      this.open.enterModal = {
        title,
        modelValue: model.help || "",
        onUpdateModelValue: async (value: string) => {
          if (await this.onUpdateHint(model, value)) {
            this.onCloseEnterModal();
          }
        },
        onClose: this.onCloseEnterModal,
      };
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.$emit("change-field", event);
    },

    isModelEnable(model: RepositoryReadModelDto | undefined) {
      if (!model) {
        return false;
      }

      if (!model.show_if_enable_attrs) {
        return true;
      }

      return this.enableAttrs.has(model.field);
    },

    getReadOnly(
      model: RepositoryReadModelDto,
      currentValue: boolean | undefined,
    ) {
      if (this.readonlyObject && this.readonlyObject[model.field]) {
        return true;
      }

      return currentValue;
    },

    getOpenHeader(groupDetailsFilled: VocGroupDetailsFilled): boolean {
      if (
        groupDetailsFilled.caption &&
        groupDetailsFilled.key in this.open.entryHeaders
      ) {
        return this.open.entryHeaders[groupDetailsFilled.key];
      }

      return true;
    },

    toggleOpenHeader(groupDetailsFilled: VocGroupDetailsFilled) {
      const currentOpen = this.getOpenHeader(groupDetailsFilled);
      this.open.entryHeaders[groupDetailsFilled.key] = !currentOpen;
    },

    getGroupDetailsKey(
      groupDetailsDto: RepositoryVocGroupDetailsDto,
      index: number,
    ) {
      return `${groupDetailsDto.caption}_${index}`;
    },
  },
});
