import { insert, Logger, TABLE_CONSTS } from "table";
import { HotkeyHelp } from "table/dist/components/TableComponent/common/components/TableHelp.vue";

const removedKeys = new Set(
  ["F2/Двойной клик мышкой", "Shift+F2", "Ctrl+Shift+F2"].map((x) =>
    x.trim().toLowerCase(),
  ),
);
export let BROWSE_EDIT_TABLE_HOTKEYS = TABLE_CONSTS.TABLE_HOTKEYS.filter(
  (x) => !removedKeys.has(x.key.trim().toLowerCase()),
);

function insertAfterHotkey(key: string, ...hotkeys: HotkeyHelp[]) {
  const index = BROWSE_EDIT_TABLE_HOTKEYS.findIndex((x) => x.key === key);
  if (index === -1) {
    Logger.warn(`'${key}' index not found`);
    return;
  }

  BROWSE_EDIT_TABLE_HOTKEYS = insert(
    BROWSE_EDIT_TABLE_HOTKEYS,
    index + 1,
    ...hotkeys,
  );
}

insertAfterHotkey(
  "F1",
  {
    key: "F2",
    text: "редактировать текущую ячейку",
  },
  {
    key: "F3/Двойной клик мышкой",
    text: "Открыть редактирование в модальном окне",
  },
);
